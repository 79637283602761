
import { Component } from '@/lib/decorator';
import Form from '@/layouts/Form';
import TitleReturn from '@/components/TitleReturn.vue';
import FormBlock from './_form.vue';
import Loading from '@/components/Loading.vue';

import UserEntityModule from '@/store/settings/users/entity';
import UserModule from '@/store/settings/users';

@Component({
  components: {
    TitleReturn,
    FormBlock,
    Loading,
  },
})
export default class UserEntityEdit extends Form {
  isLoader = true;

  get titlePage() {
    return UserModule.pageSettings.titleEdit;
  }

  returnBefore() {
    this.$router.push({ name: 'users' });
  }

  async mounted() {
    await UserEntityModule.initEdit(this.$route.params.id as string);

    this.isLoader = false;
  }
}
